body{
  background-color:#f9f9f9;  
  width:100% !important;
  margin:0;
  padding:0;
  font-size:14px;
  font-family:Arial, Helvetica, sans-serif;  
  color:#484848;
  line-height:18px; 
  
}

.confirm-container{
	width: 100%;
  display: flex;
  margin-top: 20rem;
  justify-content: center;
  align-items: center;
}

.confirm-subcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submitButton{
	background-color: #69923a;
	padding: 12px 0px;
	color: #ffffff;
	border: 0px;
	width: 186px;
  margin-top: 1rem;
	font-size: 16px;
    cursor: pointer;
}

.legal {
width:100%;  
background-color: #ffffff;
font-size:12px;
font-family:Arial, Helvetica, sans-serif;  
color:#484848;
vertical-align: bottom;
text-align: center;
padding:20px 0px 20px 0px;
position: fixed;  
bottom: 0px;

}

label.radio-label {
  padding: 0px;
  float: none;
}

p{
  padding:0px;
  margin:0px;
}

.small-size{
  
  font-size:10px;
  padding-top:10px;
  padding-bottom:10px;
  float: left;
}

.green-title{
  color:#69923a; 
  font-family:Arial, Helvetica, sans-serif; 
  font-size:20px;
  font-weight: bold;
  line-height: 25px;
}



.orange-title{
  color:#e36f1e; 
  font-family:Arial, Helvetica, sans-serif; 
  font-size:20px;
  font-weight: bold;
  line-height: 25px;
}


.blue-title{
  color:#00678e; 
  font-family:Arial, Helvetica, sans-serif; 
}


.mobile-show{
  display:none; 
  width:0px; 
  overflow:hidden;

}



.radio { 
  float: right; 
  width: 230px;
  font-family:Arial, Helvetica, sans-serif; 
  font-size:14px; 
  color:#484848;
  line-height:16px; 
  font-weight: bold; 
  text-align: left;
  padding-right:10px;
}



label { 
  float: left; 
  width: 105px;
  font-family:Arial, Helvetica, sans-serif; 
  font-size:14px; 
  color:#484848;
  line-height:16px; 
  font-weight: bold; 
  text-align: right;
  padding-right:10px;
}

input[type=text] { 
  float: left; 
  width: 260px; 
  height:20px; 
}

.error{
float: none; 
  width: 260px; 
  margin-left:120px;
  padding-top:0px;
  padding-bottom: 0px;
  height: 10px;
}

#error5.error {
  float: none; 
  width: 260px; 
  padding-top:0px;
  padding-bottom: 0px;
  height: 10px;
}

#error5.error-blink{
  animation: blinkMe 0.1s linear;
  animation-iteration-count: 3;
  float: none; 
  width: 260px; 
  margin-left:120px;
  padding-top:0px;
  padding-bottom: 0px;
  height: 10px;
}

.error-blink{
  animation: blinkMe 0.1s linear;
  animation-iteration-count: 3;
  float: none; 
  width: 260px; 
  margin-left:120px;
  padding-top:0px;
  padding-bottom: 0px;
  height: 10px;
}
@keyframes blinkMe {
  50% {
    opacity: 0;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  color: #69923a;
  height: 2.5rem;
  width: 2.5rem;
}

@keyframes spin{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error2{
float: none; 
  width: 260px; 
  padding-top:0px;
  padding-bottom: 0px;
  height: 10px;
   
}

.submitButtonContainer {
  margin-top: 5em;
}


.mainContent{
  margin:0 auto;
  width:1000px;
  position: relative;	
  height:100%;
}

.headerSection{
width:1000px;
position: relative;
overflow:hidden;
 

}

.image-section1{
  float: left;
}


.image-section1-desktop{
  float: left;
}



.social{
float:left;
}

.social_facebook{
float:left;
padding-right:30px;
}


.main-section{
width:80%;
background-color:#f9f9f9;
height:100%;
position: relative;
overflow:hidden;
  padding-top:20px;
  margin:0 auto;

}


.rounded-section {
  border-bottom: 2px solid #a1a1a1;
  padding: 40px; 
  background: #ffffff;
  width: 100%;
  border-radius: 10px;
  margin:0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.rounded-section-centered {
  border-bottom: 2px solid #a1a1a1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px; 
  background: #ffffff;
  width: 100%;
  border-radius: 10px;
  margin:0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}


.inside-section{
width:100%;
background-color:#f9f9f9;
position: relative;
  padding-top:10px;
  margin:0 auto;
  overflow:hidden;
  height: 100%;

}


.form-section{
  float: none;
  text-align: right;
  font-size:14px;
  font-family:Arial, Helvetica, sans-serif;  
  color:#484848;
  line-height:18px;
  display: table;
  margin:0 auto;
  height: 100%;
}

fieldset{
  border:0px;
}


.change-width2{
  padding-top:20px;
}

.optional-fields{
  padding-top:15px;
  font-family:Arial, Helvetica, sans-serif; 
  font-size:14px; 
  color:#484848;
  line-height:18px; 
  text-align: left;
  height:650px;
}

.provider-select{
  width:100%;
  display: block;
  clear: both;
  text-align: left;
  float: left;
  font-weight: bold;
  
}

.styled-select{
  width:100%;
  display: block;
  text-align: left;
  float: left;
  
}

.styled-select select{
 width: 132px;
 height: 25px;
}



input[type=checkbox] {
padding-right:20px;
  width: 20px;
  float:left;
  padding-right:10px;
  clear:left;
  
 
}

.checkbox-label{
  float:left;
  width: 260px;
  font-weight: normal;
  padding-top: 0px;
  text-align: left;
  
  
}


input[type=checkbox].line-checkbox {
padding-right:5px;
  width: 20px;
  float:left;
  clear: none;
}

label.line-checkbox-label{
  float:left;
  width: 70px;
  font-weight: normal;
  padding-top: 0px;
  text-align: left;
  
  
}




label.line-checkbox-label2{
  float:left;
  width: 100px;
  font-weight: normal;
  padding-top: 0px;
  text-align: left;
  
  
}


input[type=submit] {
  padding:5px 15px; 
  border:0 none;
  margin:0 auto;
}



@media only screen and (max-width: 900px) {
  .image-section1-desktop{
     display: none;
  }
  
.mainContent{
    margin:0 auto;
    width:100%;
    position: relative;	
  }
  
  .main-section{
    width:90%;
    background-color:#f9f9f9;
    height:136;
    position: relative;
    overflow:hidden;
      padding-top:20px;
      padding-left:15px;
      padding-right:15px;
      margin:0 auto;

  }
  

  
  
  .form-section{
    width: 80%;
      margin:0 auto;
      float:none;
      position: relative;
}

  
  .images-section2{
    float:none;
    margin:0 auto;
    position: relative;	
      overflow: hidden;
      width: 100%;
  }
  
  .change-width{
      width:300px;
      margin:0 auto;
      height: auto;
      padding-top:20px;
  }
  
  
  .change-width2{
      width:300px;
      margin:0 auto;
      height: auto;
  }
  
  
  label { 
  float: left; 
  width: 100%;
  font-family:Arial, Helvetica, sans-serif; 
  font-size:14px; 
  color:#484848;
  line-height:16px; 
  font-weight: bold; 
  text-align: left;
  padding-right:10px;
  padding-top:10px;
  padding-bottom:10px;
  
  
  }

  input[type=text] { 
      float: left; 
      width: 100%; 
      height:30px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box; 
     
      
  }
  
  .optional-fields{
      padding-top:15px;
      margin-left:0px;
  }
  
 .styled-select select{
     width: 100%; 
     height: 25px;
  }
  
  
  
  .error{
    float: none; 
      width: 260px; 
      margin-left:10px;
      padding-top:0px;
      padding-bottom: 40px;
      height: 10px;
  }

  #error5.error {
    float: none; 
    width: 260px; 
    padding-top:0px;
    padding-bottom: 0px;
    height: 10px;
  }
  
  #error5.error-blink{
    animation: blinkMe 0.1s linear;
    animation-iteration-count: 3;
    float: none; 
    width: 260px; 
    margin-left:120px;
    padding-top:0px;
    padding-bottom: 0px;
    height: 10px;
  }
  
  .error-blink{
    animation: blinkMe 0.1s linear;
    animation-iteration-count: 3;
    float: none; 
    width: 260px; 
    margin-left:10px;
    padding-top:0px;
    padding-bottom: 40px;
    height: 10px;
  }
  @keyframes blinkMe {
    50% {
      opacity: 0;
    }
  }

  .headerSection{
    width:100%;
    background-image: url('./navbar-components/images/spacer-right.png');
      background-size: auto;
    overflow:hidden;
      margin:0 auto;	
  }
  
  .image-section1{
      width: 139px;
      float: none;
    background-color:#006388;
      margin-left: auto;
      margin-right:auto;
      
  }
  
  
  .mobile-show{
      background-color: #004E69;
      height: 50px;
      text-align: center;font-family:Arial, Helvetica, sans-serif; 
      font-size:11px; 
      color:#ffffff;
      line-height:16px; 
      font-weight: bold;
      display: block;
      overflow: visible;
      width: 100%;
  }
  
   a.header-links{
      text-decoration:none;    
      text-align: center;
      font-family:Arial, Helvetica, sans-serif; 
      font-size:11px; 
      color:#ffffff;
      line-height:16px; 
      font-weight: bold; 
  }
  
  
  


}
